import Vue from 'vue'
import WBlock from '~/components/_globals/w-block.global.vue'
import WLayoutModal from '~/components/_globals/w-layout-modal.global.vue'
import WLogoNative from '~/components/_globals/w-logo-native.global.vue'
import WLogo from '~/components/_globals/w-logo.global.vue'
import WSupportEmailAddress from '~/components/_globals/w-support-email-address.global.vue'

Vue.component(WBlock.name || 'WBlock', WBlock)

Vue.component(WLayoutModal.name || 'WLayoutModal', WLayoutModal)

Vue.component(WLogoNative.name || 'WLogoNative', WLogoNative)

Vue.component(WLogo.name || 'WLogo', WLogo)

Vue.component(WSupportEmailAddress.name || 'WSupportEmailAddress', WSupportEmailAddress)
