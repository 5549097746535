import CommonComment from '../commons/Comment'
import Collection from '../../services/Collection'
import PseudoQueryFactory from '../../services/PseudoQuery'
import DocumentPersona from './Persona'

const DEFAULT_MODEL = {
  commentId: null,
  content: '',
  responses: [],
  reactions: {
    like: 0
  },
  user: {}
}

export default class DocumentComment extends CommonComment {
  static modelProperties = DEFAULT_MODEL

  $rehydratation (data = {}) {
    if (data.user) {
      data.user = new DocumentPersona(data.user)
    }

    if (data.responses) {
      data.responses = data.responses.map(comment => new DocumentComment(comment))
    }

    return super.$rehydratation(data)
  }
}
