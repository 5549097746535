import { CONCEPTION_UNSETABLE_MODEL_GETTER } from './constants'
import BaseError from './BaseError'

export { CONCEPTION_UNSETABLE_MODEL_GETTER }

export default class SpokeConceptionError extends BaseError {
  constructor (code, ...additionals) {
    super()
    this.code = code
    let message = 'conception error'

    if (code) {
      message += '\n'
      message += `- Error code : ${code}`
      message += '\n'
      message += '- Additional data :'
      message += '\n'
      message += JSON.stringify(additionals)
    }

    this.message = `${this.name}: ${message}`
  }
}
