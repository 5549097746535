/**
 * @api public
 * @description project configuration
 */
export const DEFAULT_CONFIGURATION = {
  apiMode: 'development',
  apiVersion: '2.1',
  axios: undefined,
  debug: false,
  options: {
    defaultImageSrc: 'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wBDAAICAgICAgICAgIDAgICAwQDAgIDBAUEBAQEBAUGBQUFBQUFBgYHBwgHBwYJCQoKCQkMDAwMDAwMDAwMDAwMDAz/2wBDAQMDAwUEBQkGBgkNCgkKDQ8ODg4ODw8MDAwMDA8PDAwMDAwMDwwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAwMDAz/wAARCAABAAEDAREAAhEBAxEB/8QAFAABAAAAAAAAAAAAAAAAAAAACP/EABQQAQAAAAAAAAAAAAAAAAAAAAD/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/8QAFBEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEQMRAD8ASAP/2Q=='
  }
}

export const DEFAULT_APIS_ENDPOINTS = {
  next: {
    rest: `https://next.brocoli.io/rest/`,
    ws: `wss://next.brocoli.io/realtime/`
  },
  production: {
    rest: `https://apis.brocoli.io/rest/`,
    ws: `wss://apis.brocoli.io/realtime/`
  },
  staging: {
    rest: `https://staging.brocoli.io/rest/`,
    ws: `wss://staging.brocoli.io/realtime/`
  },
  development: {
    rest: `https://dev.brocoli.io/rest/`,
    ws: `wss://dev.brocoli.io/realtime/`
  }
}
export const DEFAULT_APIS_VERSIONS = '2'
