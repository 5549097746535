import $get from 'lodash.get'
import $merge from 'lodash.merge'

import { UnsupportedMethodError } from '../../utils/error'
import CommonContent from '../commons/Content'
import MediaPlayer from '../../services/MediaPlayer'
import Messager from '../../services/Messager'
import { isArrayOfObject } from '../../utils/check-types'
import warn from '../../utils/warning'
import DocumentAudio from './Audio'
import DocumentConversation from './Conversation'
import DocumentImage from './Image'
import DocumentMedia from './Media'

const computeAudiosDuration = (audios) => {
  return audios.reduce((prev, audio) => {
    return prev + audio.$metadata('duration')
  }, 0)
}

const computeCaptionProperty = (metadatas) => {
  const keys = Object.keys(metadatas)
    .filter(k =>
      ['heading', 'cards', 'thumbnail'].indexOf(k) >= 0
    )

  return keys.reduce((obj, key) => {
    return {
      ...obj,
      [key]: metadatas[key]
    }
  }, {})
}

export default class DocumentContent extends CommonContent {
  /**
   * @api private
   * options passed to mediaplayer for this content
   */
  #playOptions = {}

  /**
   * @getter
   * @type {Boolean}
   * @description `true` if the current content is played by MediaPlayer
   */
  get isPlaying () {
    return MediaPlayer.isPlayingContent(this)
  }

  getters (rehydrated, context) {
    const conversations = rehydrated('conversations') || []
    const metadatas = rehydrated('metadatas')
    const audios = metadatas.audios
    const documents = metadatas.documents
    const links = metadatas.links

    return {
      captions: computeCaptionProperty(metadatas),
      documents,
      duration: computeAudiosDuration(audios),
      episodes: audios,
      messager: conversations.find(conversation => conversation.type === 'comment'),
      links,
      title: rehydrated('name')
    }
  }

  $caption (selector) {
    if (typeof selector !== 'string') {
      throw new Error('selector should be a string')
    }

    const [ slug, it ] = selector.split(':')
    const caption = this.$metadata(slug)

    let res = new DocumentImage()
    if (Array.isArray(caption)) {
      const captions = caption.map(cap => new DocumentImage(cap))

      if (it) {
        switch (it) {
          case 'first':
            res = captions[0]
            break
          case 'last':
            res = captions[captions.length - 1]
            break
        }
      } else {
        res = captions
      }
    } else if (typeof caption === 'string') {
      res = new DocumentImage(caption)
    }

    return res
  }

  $conversations (type) {
    return !type
      ? this.$data('conversations', [])
      : this.$data('conversations', []).find(conv => conv.type === type)
  }

  $documents () {
    return this.$metadata('documents', [])
  }

  $episode (position) {
    const audios = this.$episodes()

    return audios[position] || null
  }

  $episodes () {
    return this.$metadata('audios', [])
  }

  $links () {
    return this.$metadata('links', [])
  }

  $sources () {
    warn(true, 'deprecated $sources, use $episodes instead')

    return this.$episodes()
  }
  /**
   * @todo
   * current limitation
   * conversations and subcontents are not rehydrated after the initial render
   */
  $rehydratation (data = {}) {
    const { conversations = [], metadatas = this.data.metadatas } = data

    // conversations is setup when we receive the conversations
    // object for the first time
    // if data.conversations is empty, we reseting the previous set data
    // this is a need, conversations can be fetched only if ?withConversations
    // parameter is set when dev make a query
    if (isArrayOfObject(data.conversations)) {
      data.conversations = data.conversations.map((conversation) => {
        return new Messager(this, new DocumentConversation(conversation))
      })
    } else {
      data.conversations = this.$data('conversations')
      // cannot be reset during a rehydratation
      delete data.conversations
    }

    const audios = $get(data, 'metadatas.audios', []).map((audio, index) => new DocumentAudio(audio, this, index))
    const documents = $get(data, 'metadatas.documents', []).map(doc => new DocumentMedia(doc, 'documents'))
    const links = $get(data, 'metadatas.links', []).map(doc => new DocumentMedia(doc, 'links'))

    data.metadatas = $merge({}, metadatas, {
      audios,
      documents,
      links
    })

    const formated = Object.assign({}, this.modelProperties, data)

    return super.$rehydratation(formated)
  }

  /**
   * @api public
   */
  pause () {
    return MediaPlayer.pause()
  }

  /**
   * @api public
   */
  play (episodeIndex = 0, options) {
    this.#playOptions = options

    MediaPlayer.setContent(this, episodeIndex, this.#playOptions)
    return MediaPlayer.play()
  }

  /**
   * @api public
   */
  next () {
    if (this.$episode(MediaPlayer.episodeIndex + 1)) {
      MediaPlayer.stop()
      MediaPlayer.setContent(this, MediaPlayer.episodeIndex + 1, this.#playOptions)
      return MediaPlayer.play()
    }
  }

  /**
   * @api public
   */
  prev () {
    if (this.$episode(MediaPlayer.episodeIndex - 1)) {
      MediaPlayer.stop()
      MediaPlayer.setContent(this, MediaPlayer.episodeIndex - 1, this.#playOptions)
      return MediaPlayer.play()
    }
  }

  /**
   * @api public
   */
  replay () {
    return this.seek(0).play()
  }

  /**
   * @api public
   * @param {number} time
   */
  seek (time = 0) {
    return MediaPlayer.setCurrentTime(time)
  }

  /**
   * @api public
   */
  stop () {
    return MediaPlayer.stop()
  }

  delete () {
    throw new UnsupportedMethodError('delete')
  }

  post () {
    throw new UnsupportedMethodError('post')
  }

  put () {
    throw new UnsupportedMethodError('put')
  }
}
