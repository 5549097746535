import Model from '../services/Model'

export const isArrayOfObject = (arr) => {
  const isArray = Array.isArray(arr) && arr.length > 0

  return isArray && arr[0] && typeof arr[0] === 'object' && !!arr[0].id
}

export const isInstanceOfModel = (instance) => {
  return instance instanceof Model
}
