import { formatDate, formatDateHour } from '~/utils/dates'

export default {
  methods: {
    formatDate(date) {
      return formatDate(date, this.$i18n.locale)
    },
    formatDateHour(date) {
      return formatDateHour(date, this.$i18n.locale)
    },
  },
}
