import Model from '../../services/Model'
import CommonPersona from './Persona'

/**
 * @class Comment
 * @extends Model
 * @description
 * Comment model
 */
export default class CommonComment extends Model {
  static modelName = 'Comment'
  static resource = 'comments'

  constructor (data) {
    super('comments', data)
  }
}
