import $merge from 'lodash.merge'

import Authenticate from '../../services/Authenticate'
import Model from '../../services/Model'
import getSiteInstance from '../../services/Site'
import {
  UnsupportedMethodError,
  UnsupportedPropertyError
} from '../../utils/error'

const DEFAULT_NAME = `anonyme`
const DEFAULT_MODEL = {
  active: true,
  login: '',
  metadatas: {
    name: DEFAULT_NAME
  },
  protected: {
    email: ''
  }
}

export default class CommonUser extends Model {
  static modelName = 'User'
  static modelProperties = DEFAULT_MODEL
  static resource = 'users'

  /**
   * @api public
   * @description true if current session is authenticated
   * @default null
   */
  isAuthenticated = null

  constructor(data) {
    super('users', $merge({}, DEFAULT_MODEL, data))
  }

  getters(rehydrated) {
    return {
      isAnonymous: rehydrated('metadatas.name', DEFAULT_NAME) === DEFAULT_NAME,

      // `shallow user` define a fake user stored on local storage
      // if true - the site is public
      isShallowUser: undefined
    }
  }

  /**
   * @api public
   * @param {object} options
   * @description
   * Call the /me api route and rehydrate the user data object
   * @returns {promise<User>}
   */
  async me(options, shouldRehydrate = true) {
    const { item } = await this.__http.get(`/me`, options)

    return shouldRehydrate ? this.$rehydrate(item) : this
  }

  delete() {
    throw new UnsupportedMethodError('delete')
  }
}
