import EventEmitter from 'eventemitter3'

import NetworkDetection from '../utils/network-detection'

const noop = _ => _

class Analytics extends EventEmitter {
  #networkActiveAt = new Date()
  #networkInactiveAt = null

  /**
   * @api private
   * @type {boolean}
   * @description
   * true if the user is away (screen off...)
   */
  #screenIsSuspended = true

  /**
   * @api public
   * @type {date}
   */
  #screenActiveAt = new Date()

  /**
   * @api private
   * @type {date}
   * @description
   * time where the current screen was of
   */
  #screenInactiveAt = null

  /**
   * @api public
   * @type {boolean}
   * @description true if user has no active network connection
   */
  isOffline = false

  /**
   * @api public
   * @type {boolean}
   * @description true if the window is visible
   */
  isVisible = true

  get isAway () {
    return this.#screenIsSuspended
  }

  setup ({ isBrowser, mediaplayer, realtime }) {
    // this setup is usefull in browser mode only
    if (isBrowser) {
      const visibilityWatcher = require('visibility')()

      // attaching watcher for browsers : check network connection
      // when user connection is off, we need to perform some operations
      NetworkDetection.on('change', (offline) => {
        this.emit('offline', offline)

        if (offline) {
          this.#networkInactiveAt = new Date()
        }

        if (this.isOffline === true && offline === false) {
          this.#networkActiveAt = new Date()

          const awaySince = Math.abs(this.#networkActiveAt.getTime() - this.#networkInactiveAt.getTime())
        }

        this.isOffline = offline
      })

      // attaching watcher for browsers : check screen visibility
      // when user has no screen activity and is not playing any
      // media, we sending to the realtime api the monitor: false
      // event to stop tracking
      visibilityWatcher.on('change', (visible) => {
        this.emit('visibility', visible)

        // if the current state of the window is 'visible'
        if (visible === true) {
          this.#screenActiveAt = new Date()

          // this fucking variable can be null (in dev mode essentially)
          // when a development process as HotModuleReload reload some
          // parts of code while a developer doing some things on another
          // screen
          if (!this.#screenInactiveAt) {
            this.#screenInactiveAt = new Date()
          }

          const awaySince = Math.abs(this.#screenActiveAt.getTime() - this.#screenInactiveAt.getTime())

          // ... and previous state was suspended
          // we should reemit the previous state
          if (this.#screenIsSuspended === true) {
            realtime.publish('monitor', { away: false }).catch(noop)
            this.#screenIsSuspended = false
          }

          this.#screenInactiveAt = null
        } else {
          this.#screenInactiveAt = new Date()

          if (mediaplayer.inActivity === false) {
            realtime.publish('monitor', { away: true }).catch(noop)
            this.#screenIsSuspended = true
          } else {

          }
        }

        this.isVisible = visible
      })
    }
  }
}

export default new Analytics()
