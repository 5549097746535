import { format, parseISO } from 'date-fns'
import { fr, enGB } from 'date-fns/locale'

const LOCALES = {
  en: enGB,
  fr,
}

export const formatDate = (date, locale) => {
  return format(typeof date === 'string' ? parseISO(date) : date, `d LLL yyy`, {
    locale: LOCALES[locale] || fr,
  })
}

export const formatDateHour = (date, locale) => {
  return format(
    typeof date === 'string' ? parseISO(date) : date,
    `d LLL yyy 'à' k:mm:ss`,
    {
      locale: LOCALES[locale] || fr,
    }
  )
}
