import { BAD_CONFIGURATION } from './constants'
import BaseError from './BaseError'

export default class SpokeConfigurationError extends BaseError {
  constructor (property, value) {
    super()

    let message = 'bad configuration'

    if (property) {
      message += '\n'
      message += ' ---> '
      message += `${property} is not as expected, you passed ${value} (type "${typeof value}")`
    }

    this.message = `${this.name}: ${message}`
    this.code = BAD_CONFIGURATION
    this.property = property
    this.value = value
  }
}
