import $merge from 'lodash.merge'

import {
  getConfig,
  isPublicRoute,
  isRouteDefinedInKey,
  findPageFromSlug,
} from '~/utils/voicer'
import spoke from '~/utils/spoke'
import doMutations from '~/utils/mutate'

export default ({
  app,
  redirect,
  route,
  store: { commit, dispatch, state },
}) => {
  const authMutation = doMutations('auth')(commit)
  const contentsMutation = doMutations('contents')(commit)
  const rootMutation = doMutations('root')(commit)
  const logDebug = (...text) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(...text)
    }
  }

  try {
    if (state.root.isSpokeConfigured === false) {
      spoke.configure(
        $merge(
          {
            apiMode: process.env.BROCOLI_API_MODE,
            apiVersion: process.env.BROCOLI_API_VERSION,
            siteId: getConfig('siteId'),
            session: state.auth.user.session,
            user: state.auth.user,
          },
          getConfig('forceSpokeConfiguration', {})
        )
      )

      spoke.realtime.on('connect', () => {
        logDebug(`[realtime] connected`)
      })

      spoke.realtime.on('connect_error', (error) => {
        logDebug(`[realtime] connection error`, error)
      })

      spoke.realtime.on('monitor_error', (error) => {
        logDebug(`[realtime] monitor error`, error)
      })

      spoke.realtime.on('parsing_error', (error) => {
        logDebug(`[realtime] parsing error`, error)
      })

      spoke.realtime.on('reconnect', () => {
        logDebug(`[realtime] reconnected`)
      })

      logDebug('spoke version : ', spoke.version)
    }

    return new Promise((resolve, reject) => {
      spoke.on('configurationError', (error) => {
        authMutation('SET_LOADER', false)
        contentsMutation('SET_LOADER', false)

        if (error.component === 'api') {
          rootMutation('SET_SPOKE_DEAD', true)
        } else {
          rootMutation('SET_SPOKE_CONFIGURED', true)
        }
        reject(error)
      })

      spoke.user.on('authenticated', () => {
        authMutation(
          'SET_AUTHENTICATED',
          spoke.user.isAuthenticated || spoke.site.optAuthentication === false
        )
      })

      spoke.ready(
        async () => {
          // If spoke is alive, then set the user to authenticated
          // It is important for protected pages purpose to setAuthenticated before configured

          try {
            rootMutation('SET_SPOKE_CONFIGURED', true)
            authMutation('SET_LOADER', false)
            authMutation('SET_USER', spoke.user.toJSON())
            rootMutation('SET_SITE', spoke.site.toJSON())
          } catch (error) {
            logDebug(error)
            authMutation('SET_LOADER', false)
            contentsMutation('SET_LOADER', false)
            rootMutation('SET_SPOKE_DEAD', true)
          }

          app.$tracking.identifyUser()

          const consideredAuthenticated =
            spoke.user.isAuthenticated || spoke.site.optAuthentication === false
          const cmpIsPublicRoute = isPublicRoute(route.name)

          if (consideredAuthenticated) {
            if (route && route.name === 'login') {
              return redirect('/')
            }

            const { mode: routeMode = 'default' } = route.query

            // attach player handlers (see store/modules/player)
            dispatch('player/installMediaplayer')

            try {
              if (isRouteDefinedInKey(route, 'store.contents/getContents')) {
                await dispatch(
                  'contents/getContents',
                  findPageFromSlug(routeMode, 'options')
                )
              }
              if (isRouteDefinedInKey(route, 'store.filters/getCategories')) {
                await dispatch('filters/getCategories')
              }
              if (isRouteDefinedInKey(route, 'store.contents/getContent')) {
                await dispatch('contents/getContent', route)
              }
            } catch (error) {
              logDebug(error)
              reject(error)
              authMutation('SET_LOADER', false)
              contentsMutation('SET_LOADER', false)
              rootMutation('SET_SPOKE_DEAD', true)
            }
          } else if (cmpIsPublicRoute === false) {
            return redirect('/login')
          }

          resolve()
        },
        (error) => {
          logDebug(error)
          reject(error)
        }
      )
    })
  } catch (error) {
    authMutation('SET_LOADER', false)
    contentsMutation('SET_LOADER', false)
    rootMutation('SET_SPOKE_DEAD', true)
    logDebug(error)
    throw error
  }
}
