import $merge from 'lodash.merge'

import Model from '../../services/Model'
import { UnsupportedMethodError } from '../../utils/error'

const DEFAULT_MODEL = {
  type: 'comment'
}

export default class BaseConversation extends Model {
  static modelName = 'Conversation'
  static modelProperties = DEFAULT_MODEL
  static resource = 'conversations'

  constructor (data) {
    super(`conversations`, $merge({}, DEFAULT_MODEL, data))
  }

  delete () {
    throw new UnsupportedMethodError('delete')
  }

  put () {
    throw new UnsupportedMethodError('put')
  }
}
