import {
  BAD_RT_AUTHENTICATION,
  UNKNOWN_RT_RESPONSE
} from './constants'
import BaseError from './BaseError'

export default class SpokeRealtimeError extends BaseError {
  constructor (status, error) {
    super()

    let code
    let message = 'realtime error'

    if (status) {
      switch (status) {
        case 'connect_error':
        case 'missing_token':
          code = BAD_RT_AUTHENTICATION
          break
        default:
          code = UNKNOWN_RT_RESPONSE
          break
      }
    }

    if (code) {
      message += '\n'
      message += `- Realtime status code ${status} : ${code}`
      message += '\n'
      message += `- Details : `
      message += '\n  '
      message += error ? error.toString() : 'no trace'
    }

    this.message = `${this.name}: ${message}`

    this.component = 'realtime'
    this.code = code
    this.status = status
    this.originalError = error
  }
}
