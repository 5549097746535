import CommonSite from '../commons/Site'

export default class DocumentSite extends CommonSite {
  getters (rehydrated) {
    return {
      name: rehydrated('name'),
      optAuthentication: rehydrated('optAuthentication', rehydrated('metadatas.public_can_read'), false),
      optRegistrable: rehydrated('optRegistrable', rehydrated('metadatas.public_can_create_account'), false)
    }
  }
}
