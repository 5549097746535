import $merge from 'lodash.merge'

import routeResolve from '../utils/route-resolve'
import Garbage from './Garbage'

const DEFAULT_QUERY = {
  withConversations: true,
  withMedias: true,
  withMetadatas: true,
  withTags: true
}

export default class Collection {
  constructor(model) {
    // provided after a `get` request
    this.meta = {
      limit: null,
      offset: 0,
      more: false
    }
    this.model = model
    this.resource = model.resource
  }

  /**
   * @api public
   * @param {object} data data automatically stored in the model
   * @returns {Model}
   */
  create(data) {
    const Model = this.model
    const document = new Model(data)

    return document
  }

  /**
   * @api public
   * @param {object} query
   */
  get(query) {
    const params = { ...DEFAULT_QUERY, ...query }

    return this.__http.get(this.resource, { params }).then((response) => {
      this.meta = response.meta
      return response.items
    })
  }

  /**
   * @api public
   * @param {string} pseudoId
   */
  getOne(pseudoId = '', query) {
    const document = pseudoId && Garbage.find(pseudoId)
    const params = $merge({}, DEFAULT_QUERY, query)

    if (document) {
      return document
    }

    return this.__http
      .get(routeResolve(this.resource, pseudoId), { params })
      .then((response) => {
        return response.item
      })
  }
}
