const middleware = {}

middleware['privacy'] = require('../middleware/privacy.js')
middleware['privacy'] = middleware['privacy'].default || middleware['privacy']

middleware['protection'] = require('../middleware/protection.js')
middleware['protection'] = middleware['protection'].default || middleware['protection']

middleware['router-intelligence-detector'] = require('../middleware/router-intelligence-detector.js')
middleware['router-intelligence-detector'] = middleware['router-intelligence-detector'].default || middleware['router-intelligence-detector']

middleware['router-save-location'] = require('../middleware/router-save-location.js')
middleware['router-save-location'] = middleware['router-save-location'].default || middleware['router-save-location']

export default middleware
