import $merge from 'lodash.merge'

import {
  APPEND_COMMENT,
  APPEND_THREAD,
  RESET_INITIAL_STATE,
  SET_COMMENTS,
  SET_COMMENTS_VISIBILITY,
  SET_LOADER,
  SET_PARENT_ID,
  SET_OPTIONS,
  SET_THREADS,
  SET_WATCHING_STATUS,
} from './types'
import notifier from '~/utils/notifier'
import spoke from '~/utils/spoke'
import { runExcentricFile } from '~/utils/voicer'

const excentrics = runExcentricFile('store', 'comments')

const INITIAL_STATE = () => ({
  /**
   * @type {Boolean}
   * `true` when a process is loading contents
   */
  isLoading: false,

  /**
   * @type {Boolean}
   */
  isWatching: false,

  /**
   * @type {Boolean}
   */
  isVisible: false,

  /**
   * @type {Object}
   * will be overrided by current content messager
   * options
   */
  options: {
    moderated: false,
    notify: true,
    reactable: true,
    subcomments: true,
  },

  /**
   * @type {ObjectID}
   * object id of the parent comment id
   */
  parentId: null,

  /**
   * @type {Array}
   */
  threads: [],

  /**
   * @type {Array}
   */
  comments: [],
})

export const state = () => ({
  ...$merge(INITIAL_STATE(), excentrics.state),
})

export const actions = $merge(
  {
    /**
     * This action will fetch comments of a given content
     * Content should be present in the `player.content` property
     * and accessible via `spoke.item`
     * @returns {Promise}
     *
     * @todo
     * unwatch messenger
     */
    getComments({ rootState, state, commit, dispatch }) {
      const notify = notifier(dispatch)

      // Set the UI to loading state
      commit(SET_LOADER, true)
      commit(SET_COMMENTS, [])
      commit(SET_THREADS, [])

      const content = spoke.item(rootState.player.content)
      const messager = content.messager

      commit(SET_OPTIONS, messager.options)

      messager
        .watch()
        .on('comment', (comment) => {
          commit(APPEND_THREAD, comment.lean())
        })
        .get() // return a pseudoQuery
        .on('success', (comments) => {
          commit(SET_COMMENTS, [])
          commit(SET_THREADS, comments.lean().toArray())
          commit(SET_LOADER, false)
        })
        .on('error', (error) => {
          notify('error', this.app.i18n.t('fetch_failed'), error)
          commit(SET_LOADER, false)
        })
    },

    /**
     * Post a comment as response or new thread
     * @param {String} message sent within /POST
     */
    async postComment({ rootState, state, commit, dispatch }, message) {
      const notify = notifier(dispatch)

      try {
        if (!state.parentId) {
          await spoke.item(rootState.player.content).messager.send(message)
        } else {
          await spoke
            .item(rootState.player.content)
            .messager.thread(state.parentId)
            .send(message)
        }
        notify('success', this.app.i18n.t('comment_added'))
      } catch (error) {
        notify('error', this.app.i18n.t('impossible_comment_add'), error)
      }
    },

    /**
     * Switch the comment modal opened or closed
     */
    async toggleCommentsVisibility({ state, rootState, commit, dispatch }) {
      const isVisible = !state.isVisible
      await commit(SET_COMMENTS_VISIBILITY, isVisible)

      // applyied once mutation is effetive
      if (isVisible) {
        dispatch('getComments')
      } else {
        // unwatch previous opened messager and destroy
        spoke.item(rootState.player.content).messager.destroy()
      }
    },

    /**
     * Switch between threads and responses
     * @param {String} type can be 'threads' or 'responses'
     * @todo
     * unwatch messenger
     */
    toggleCommentType({ commit, rootState, state, dispatch }, parentId = null) {
      const notify = notifier(dispatch)

      const currentParentId = state.parentId
      const messager = spoke.item(rootState.player.content).messager

      const oldThread = parentId ? messager.thread(parentId) : null
      commit(SET_PARENT_ID, parentId)

      if (parentId) {
        commit(SET_LOADER, true)
        const thread = messager.thread(parentId)

        thread
          .watch()
          .on('comment', (comment) => {
            commit(APPEND_COMMENT, comment.lean())
          })
          .get() // return a pseudoQuery
          .on('success', (comments) => {
            commit(SET_COMMENTS, comments.lean().toArray())
            commit(SET_LOADER, false)
          })
          .on('error', (error) => {
            notify('error', null, error)
            commit(SET_LOADER, false)
          })
      } else if (!parentId && currentParentId) {
        // todo: unwatch previous thread
        commit(SET_COMMENTS, [])
        commit(SET_LOADER, false)

        // unwatch previous opened thread
        if (oldThread) {
          oldThread.unwatch()
        }
      }
    },

    resetState({ commit }) {
      commit(RESET_INITIAL_STATE)
    },
  },
  excentrics.actions
)

export const mutations = $merge(
  {
    /**
     * Display a loading state to any comments modal
     * @param {Boolean} status
     */
    [SET_LOADER](state, status) {
      state.isLoading = status
    },

    /**
     * Append a new comment in the `comments` list
     * @param {Object} comment
     */
    [APPEND_COMMENT](state, comment) {
      const comments = [...state.comments, comment]

      state.comments = comments
    },

    /**
     * Append a new thread in the `threads` list
     * @param {Object} thread
     */
    [APPEND_THREAD](state, thread) {
      const threads = [...state.threads, thread]

      state.threads = threads
    },

    /**
     * Add sub comments (issued from an active thread)
     * @param {Object} comments
     */
    [SET_COMMENTS](state, comments) {
      state.comments = comments
    },

    /**
     * Set current messager options
     * @param {Object} options
     */
    [SET_OPTIONS](state, options) {
      state.options = Object.assign({}, options)
    },

    /**
     * Add mains threads
     * @param {Object} threads
     */
    [SET_THREADS](state, threads) {
      state.threads = threads
    },

    /**
     * Define if the comment modal is open or not
     * @param {Boolean} status
     */
    [SET_COMMENTS_VISIBILITY](state, status) {
      state.isVisible = status
    },

    /**
     * Define if the modal shows threads or responses
     * @param {String} type
     */
    [SET_PARENT_ID](state, parentId) {
      state.parentId = parentId
    },

    /**
     * Define if we are currently watching something
     * @param {Boolean} status
     */
    [SET_WATCHING_STATUS](state, status) {
      state.isWatching = status
    },

    /**
     * reset vuex comments store on initial state
     */
    [RESET_INITIAL_STATE](state) {
      Object.assign(state, INITIAL_STATE())
    },
  },
  excentrics.mutations
)

export const getters = $merge(
  {
    /**
     * current messager options
     * @returns {Object} options
     */
    currentOptions(state) {
      return state.options
    },
    /**
     * Shows the current threads displayed on responses modal
     * @returns {Object}
     */
    currentThread(state) {
      return state.threads.find((thread) => thread.id === state.parentId)
    },

    currentThreadResponses(state) {
      return state.comments
    },

    /**
     * @returns {Boolean}
     */
    isLoading(state) {
      return state.isLoading
    },

    /**
     * @returns {Boolean}
     */
    isVisible(state) {
      return state.isVisible
    },

    /**
     * @returns {String} comment id
     */
    parentId(state) {
      return state.parentId
    },

    /**
     * @returns {Array} comments
     */
    threads(state) {
      return state.threads
    },
  },
  excentrics.getters
)
