import auth from './auth'
import comments from './comments'
import contents from './contents'
import filters from './filters'
import notifier from './notifier'
import player from './player'
import root from './root'

export default {
  auth,
  comments,
  contents,
  filters,
  player,
  notifier,
  root,
}
