import $get from 'lodash.get'

import BaseMedia from './Media'

/**
 * @class Images
 * @extends Model
 * @description
 * Media Image system model
 */
export default class BaseImage extends BaseMedia {
  static modelName = 'Image'

  constructor (source, metadata) {
    if (source && typeof source === 'object') {
      super({ source: source.value }, metadata)
    } else {
      super({ source }, metadata)
    }
  }

  /**
   * @override BaseMedia#exists()
   * @returns {Boolean} true if the media image exists
   */
  exists () {
    return this.$data('source') !== null
  }

  /**
   * @todo check if functional
   * @returns {String}
   * @returns {null}
   */
  get source () {
    const defaultImageSrc = $get(this.__config, 'options.defaultImageSrc', null)

    return this.exists() === true
      ? this.$data('source')
      : defaultImageSrc
  }
}
