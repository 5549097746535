import EventEmitter from 'eventemitter3'
import IWs from 'isomorphic-ws'
import mqttMatch from 'mqtt-match'

import ConceptionError from '../utils/errors/ConceptionError'
import RealtimeError from '../utils/errors/RealtimeError'
import { CONCEPTION_BAD_REALTIME_HANDLER } from '../utils/errors/constants'
import warn from '../utils/warning'
import { reset } from 'mixpanel-browser'

const log = (...params) => {
  console.log(`[rt debug]-`, ...params)
}

/**
 * @api private
 * @constant
 * @type {number}
 * @default 3000
 * @description
 * when a disconnection occures, an autoconnect
 * mechanism is automatically dispatched
 * we attempt to connect every AUTORECONNECT_RETRY_INTERVAL
 * every attempt increase AUTORECONNECT_RETRY_INTERVAL by 10%
 * (in a limit of 10 * initialValue = 30000)
 * to prevent undesired ws DoS attack
 *
 * @see #autoreconnect
 */
let AUTORECONNECT_RETRY_INTERVAL = 3000

function bumpAutoreconnectRetryInterval() {
  const inc = AUTORECONNECT_RETRY_INTERVAL * 0.1

  if (AUTORECONNECT_RETRY_INTERVAL + inc <= AUTORECONNECT_RETRY_INTERVAL * 10) {
    AUTORECONNECT_RETRY_INTERVAL += inc
  }
}

function resetAutoreconnectRetryInterval() {
  AUTORECONNECT_RETRY_INTERVAL = 3000
}

/**
 * @api private
 * @
 */
let isRunningGarbageOperation = false

class Realtime extends EventEmitter {
  /**
   * @api private
   * @description
   * usefull when you wanna debug realtime from a living webapp
   * usage with bubblecast:
   * ```
   * // in the developer console
   * window.$nuxt.$spoke.realtime.debug = true
   * ```
   * @note can be set via `.debug` setter
   */
  #__debug = false

  /**
   * @api public
   * @description set via Core.#configureURIs
   */
  baseURL = null

  /**
   * @api public
   */
  inReconnection = false

  /**
   * @api private
   * list of ws operations (watch, unwatch, subscribe, unsubscribe, publish)
   * emitted when client is not ready (CLOSE or unavailable)
   * theses operations are resumed when ws connection become available
   * prototyped as:
   * ```
   * {
   *   'timestamp': new Date().getTime(),
   *   'type': 'publish|subscribe|unsubscribe|unwatch|watch',
   *   'payload': mixed
   * }
   * ```
   */
  #garbage = []

  /**
   * @api private
   * @type array<object>
   * @description
   * some default handlers are defined in the constructor
   * when a new entry is inserted in this property, if
   * the new `topic` is already present in #handler, the
   * `handler` property is set as `array` and the callback
   * is added after the previous callback.
   * see example below:
   *
   * @example
   * [
   *   {
   *     topic: 'mytopic',
   *     handler: function (data) { console.log(data) }
   *   },
   *   {
   *     topic: 'mytopic2',
   *     handler: [
   *       myFunction1,
   *       myFunction2,
   *       myFunction3
   *     ]
   *   }
   * ]
   */
  #handlers = []

  /**
   * @api private
   */
  #subscriptions = []

  /**
   * @api private
   */
  #token = null

  /**
   * reconnection timer
   */
  #reconnectionCtx = null

  constructor() {
    super()

    // set default handlers
    // help, performance and debug
    // handle: ping
    this.#handlers.push({
      topic: 'ping',
      handler: (ts) => {
        const now = +new Date()
        const delta = now - ts

        // @todo
        // detect slow connections and emit
        // an event (network_slow ?)
        // note: delta is the value between server response / client reception
        this.publish('pong', {
          delta,
          ts
        })
      }
    })

    // handle: close
    this.#handlers.push({
      topic: 'close',
      handler: (data) => {
        this.end(true)
        this.autoreconnect()
      }
    })

    // handle: monitorError
    this.#handlers.push({
      topic: 'monitorError',
      handler: (error) => {
        this.emit('monitor_error', error)
      }
    })

    // @todo:
    // for subscription / unsubscription
    // we can add a retry mechanism if
    // the subscription is not acked before
    // the end of a TIMEOUT

    // handle: subscribed
    this.#handlers.push({
      topic: 'subscribed',
      handler: (data) => {}
    })

    // @todo:
    // handle subscription error
  }

  get debug() {
    return this.#__debug
  }

  set debug(value) {
    if (process.env.NODE_ENV !== 'production') {
      this.#__debug = value

      if (this.#__debug === true) {
        this.#log(`realtime debug mode actived`)
      }
    } else {
      this.#log(`realtime debug mode is disabled in production`)
    }
  }

  /**
   * @api public
   * @param {string} type
   * @param {object} payload
   *
   * @returns
   * @memberof Realtime
   */
  addGarbageOperation(type, payload) {
    this.#garbage.push({
      timestamp: new Date().getTime(),
      type,
      payload
    })

    return this
  }

  /**
   * @api public
   * @returns
   * @memberof Realtime
   */
  runGarbageOperations() {
    if (isRunningGarbageOperation === true) {
      return this
    }

    isRunningGarbageOperation = true
    this.#garbage.forEach((operation) => {
      this[operation.type](...operation.payload)
    })

    this.#garbage = []

    process.nextTick(() => {
      isRunningGarbageOperation = false
    })

    return this
  }

  hasActiveConnection() {
    return this.client && this.client.readyState === this.client.OPEN
  }

  /**
   * @api public
   * @param {string} siteId
   *
   * @returns
   * @memberof Realtime
   */
  configure(siteId) {
    this.siteId = siteId
    this.#log(`configured site`, this.siteId)

    return this
  }

  /**
   * @api public
   * @param {string} token
   *
   * @returns
   * @memberof Realtime
   */
  connect(token) {
    this.#token = token

    this.#log(`configured token`, token)

    if (this.client && this.client.disconnecting) {
      this.#log(`connection has been reset (forced due to connect call)`)
      this.client.end(true)
    }

    if (!token) {
      this.#log(`missing required token`)
      return Promise.reject(new RealtimeError('missing_token'))
    }

    const urlConnectionChain = [this.baseURL, this.siteId, this.#token].join(
      '/'
    )

    this.client = new IWs(urlConnectionChain)

    return new Promise((resolve, reject) => {
      this.register('authenticated', () => {
        this.runGarbageOperations()
        this.emit('connect')
        resolve()
      })

      this.register('authenticationError', (error) => {
        this.emit('connect_error', error)
        reject(new RealtimeError('connect_error', error))
      })

      // server is unavailable
      // we trying to reconnect user periodically
      this.client.onclose = () => {
        this.end(true)

        if (this.inReconnection === false) {
          this.autoreconnect()
        }
      }

      this.client.onerror = (error) => {
        reject(error)
      }

      this.client.onopen = () => {
        const executeHandlers = (handlers, data) => {
          if (typeof handlers === 'function') {
            handlers(data)
          } else if (Array.isArray(handlers)) {
            handlers.forEach((handler) => {
              executeHandlers(handler, data)
            })
          } else {
            throw new ConceptionError(CONCEPTION_BAD_REALTIME_HANDLER)
          }
        }

        this.client.onmessage = (message) => {
          try {
            const data = JSON.parse(message.data)
            // note(dev)
            // uncomment the line below to display every
            // websocket responses
            // console.log('received data over ws', data)
            const { topic } = data

            this.#log('message', 'received valid message on topic', topic)
            const executeHandlers = (hHandler, data, topic) => {
              const execIfFunction = (handler) => {
                if (typeof handler !== 'function') {
                  throw new Errror(
                    `passed handler is not a function (topic ${topic})`
                  )
                }
                return handler(data && data.message ? data.message : data)
              }

              if (Array.isArray(hHandler)) {
                for (let i = 0; i < hHandler.length; i++) {
                  execIfFunction(hHandler[i], data)
                }
              } else {
                execIfFunction(hHandler, data)
              }
            }

            this.#handlers.forEach(({ topic: hTopic, handler: hHandler }) => {
              if (typeof hTopic !== 'string' || typeof topic !== 'string') {
                return false
              }
              if (mqttMatch(hTopic, topic)) {
                this.#log(
                  'message',
                  'execute handler due to route match for topic',
                  topic
                )
                this.#log('message', 'handler', hHandler)
                try {
                  executeHandlers(hHandler, data, hTopic)
                } catch (error) {
                  console.error(`error on topic`, hTopic)
                  this.emit('parsing_error', error)
                }
              }
            })
          } catch (error) {
            this.emit('parsing_error', error)
          }
        }
      }
    })
  }

  /**
   * @api public
   *
   * @returns
   * @memberof Realtime
   */
  autoreconnect() {
    if (this.hasActiveConnection()) {
      // unecessary operation
      // ws connection is not closed
      resetAutoreconnectRetryInterval()
      return this
    }

    this.end() // important
    this.inReconnection = true

    this.#reconnectionCtx = setTimeout(async () => {
      try {
        await this.connect(this.#token)
        clearInterval(this.#reconnectionCtx)
        this.inReconnection = false
        // automatically resubscribe topics
        // once reconnection
        this.#subscriptions.forEach((topic) => {
          this.subscribe(topic)
        })
        this.emit('reconnect')
        resetAutoreconnectRetryInterval()
      } catch (error) {
        this.emit('reconnect_error', error)
        this.autoreconnect()
        bumpAutoreconnectRetryInterval()
      }
    }, AUTORECONNECT_RETRY_INTERVAL)

    return this
  }

  /**
   * @api public
   *
   * @param {string} topic
   * @param {object} message
   *
   * @returns
   * @memberof Realtime
   */
  publish(topic, message) {
    if (!this.hasActiveConnection()) {
      this.addGarbageOperation('publish', [topic, message])
      return Promise.resolve()
    }

    this.#log('publish', 'publishing new message on topic', topic)
    return new Promise((resolve, reject) => {
      try {
        const buffer = new Blob([JSON.stringify({ topic, message })], {
          type: 'application/json'
        })

        this.client.send(buffer)
        resolve()
      } catch (error) {
        reject(error)
      }
    })
  }

  /**
   * @api public
   *
   * @param {string} topic
   * @returns
   * @memberof Realtime
   */
  subscribe(topic) {
    if (!this.hasActiveConnection()) {
      return this.addGarbageOperation('subscribe', [topic])
    }

    this.#log('subscribe', 'subscribing topic', topic)
    this.#subscriptions.push(topic)
    this.publish('subscribe', { topic })

    return this
  }

  /**
   * @api public
   *
   * @param {string} topic
   * @returns
   * @memberof Realtime
   */
  unsubscribe(topic) {
    if (!this.hasActiveConnection()) {
      return this.addGarbageOperation('unsubscribe', [topic])
    }

    this.#log('unsubscribe', 'unsubscribing topic', topic)
    this.#subscriptions = this.#subscriptions.filter((t) => t !== topic)
    this.publish('unsubscribe', { topic })

    return this
  }

  /**
   * @api public
   *
   * @param {string} topic
   * @param {function} handler
   * @param {boolean} [subscribe=true]
   *
   * @returns
   * @memberof Realtime
   */
  watch(topic, handler, subscribe = true) {
    if (!this.hasActiveConnection()) {
      this.#log(
        'unwatch',
        `can't watch a topic if connection is inactive`,
        topic
      )
      return this.addGarbageOperation('watch', [handler, subscribe])
    }

    if (subscribe) {
      this.subscribe(`/sites/${this.siteId}/${topic}`)
    }

    this.register(topic, handler)

    return this
  }

  /**
   * @api public
   *
   * @param {string} topic
   * @param {boolean} [unsubscribe=true]
   *
   * @returns
   * @memberof Realtime
   */
  unwatch(topic, unsubscribe = true) {
    if (!this.hasActiveConnection()) {
      this.#log(
        'unwatch',
        `can't unwatch a topic if connection is inactive`,
        topic
      )
      return this.addGarbageOperation('unwatch', [topic, unsubscribe])
    }

    this.unregister(topic)

    if (unsubscribe === true) {
      this.unsubscribe(`sites/${this.siteId}/${topic}`)
    }

    return this
  }

  register(topic, handler) {
    const topicExists = this.#handlers.findIndex((h) => h.topic === topic)

    if (topicExists < 0) {
      this.#log('register', 'creating new registration for topic', topic)
      this.#handlers.push({ topic, handler })
    } else {
      this.#log('register', 'adding new registration for topic', topic)
      this.#handlers[topicExists].handler = [
        this.#handlers[topicExists].handler,
        handler
      ]
    }

    return this
  }

  unregister(topic) {
    this.#log('unregister', 'unregister topic', topic)
    this.#handlers = this.#handlers.filter(({ topic: hTopic }) => {
      return topic !== hTopic
    })

    return this
  }

  /**
   * @api public
   *
   * @param {boolean} [force=false]
   *
   * @returns
   * @memberof Realtime
   */
  end(force = false) {
    if (!this.client) {
      warn(true, 'client was not ready')
      return this
    }

    this.unregister('authenticated')
    this.unregister('authenticationError')
    this.unregister('monitorError')
    this.unregister('ping')
    this.unregister('close')
    this.off('connect')
    this.off('connect_error')
    this.off('reconnect')
    this.off('reconnect_error')

    try {
      this.client.close()
    } catch (error) {
      console.error('Could not close ws', error)
    }
    return this
  }

  #log(...params) {
    if (this.debug === true) {
      log(...params)
      return true
    }

    return false
  }
}

export default new Realtime()
