import $merge from 'lodash.merge'

import Model from '../../services/Model'

const DEFAULT_MODEL = {
  name: '',
  slug: '',
  tags: []
}

export default class CommonTag extends Model {
  static modelName = 'Tag'
  static modelProperties = DEFAULT_MODEL
  static resource = 'tags'

  constructor (data) {
    super('tags', $merge({}, DEFAULT_MODEL, data))
  }
}
