import $get from 'lodash.get'

const DEFAULT_CAPTION = {
  cdnSrc: '',
  originSrc: '',
  lazySrc: ''
}

const getCaption = (metadata = {}) => {
  const originSrc = $get(metadata, 'value', '')
  const cdnSrc = $get(metadata.metadatas, 'res_base.value', originSrc)
  const lazySrc = $get(metadata.metadatas, 'res_strict.value', originSrc)

  return {
    cdnSrc,
    originSrc,
    lazySrc: `data:image/jpeg;base64,${lazySrc}`
  }
}

export const $caption = (document, slug, defaultValue = DEFAULT_CAPTION) => {
  const [ ident, selector ] = slug.split(':')
  const metadata = $get(document, `captions[${ident}]`)

  if (!metadata) {
    return defaultValue
  } else if (Array.isArray(metadata)) {
    if (selector) {
      switch (selector) {
        case 'first':
          return getCaption(metadata[0])
        case 'last':
          return getCaption(metadata[metadata.length - 1])
        default:
          return getCaption(metadata[parseInt(selector, 10)])
      }
    } else {
      return getCaption(metadata[0])
    }
  }

  return getCaption(metadata)
}

export const $data = $get

export const $metadata = (document, slug, defaultValue) => {
  return $get(document, `metadatas[${slug}]`, defaultValue)
}

export const $protected = (document, slug, defaultValue) => {
  return $get(document, `protected[${slug}]`, defaultValue)
}

export default {
  $caption,
  $data,
  $metadata,
  $protected
}
