/**
 * @todo
 * make a test over > 100000 uniqueID generations
 * to check if the length is suffisant to prevent
 * duplicates
 */
function chr4 () {
  return Math.random().toString(16).slice(-4)
}

export default function uniqueID () {
  return chr4() + chr4() + chr4() + chr4()
}
